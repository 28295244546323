import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { TitleProps } from '../Routes';
import { SystemConfigContent } from './Components/SystemConfigContent';
import { SystemConfigRootStore } from './Stores/SystemConfigRootStore';

const systemConfigRootStore = new SystemConfigRootStore();

const stores = {
  systemConfigRootStore,
};

export const SystemConfig = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={false}>
      <SystemConfigContent />
    </Page>
  </Provider>
);
