import { SystemConfig } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface SystemConfigRootState {
  enabled: boolean;
  workerUiPrimaryColor: string | null;
  image: File | null;
}

export class SystemConfigRootStore {
  @observable
  public editableSystemConfigRoot?: SystemConfig;

  @observable
  public editableSystemConfigRootImage?: Blob;

  @observable
  public state: SystemConfigRootState = {
    enabled: false,
    workerUiPrimaryColor: null,
    image: null,
  };

  @action
  public setState = (newState: Partial<SystemConfigRootState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };
  };

  @action
  public resetStore = (): void => {
    this.setState({
      enabled: false,
      workerUiPrimaryColor: null,
      image: null,
    });

    this.editableSystemConfigRoot = undefined;
    this.editableSystemConfigRootImage = undefined;
  };

  @action
  public resetStoreEdit = (): void => {
    if (this.editableSystemConfigRoot === undefined) {
      return;
    }

    this.setState({
      enabled: this.editableSystemConfigRoot.enabled,
      workerUiPrimaryColor: this.editableSystemConfigRoot.workerUiPrimaryColor,
      image: null,
    });
  };

  @action
  public setFile = (file?: File): void => {
    if (!file) {
      return;
    }

    this.setState({
      image: file,
    });
  };

  @action
  public removeFile = (): void => {
    this.setState({
      image: null,
    });
  };
}
